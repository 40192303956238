/*! {!project_name!} v1.0.3 */
/**
 * CSS Stylesheet for: {!project_name!} v1.0.3
 *
 * Author: {!dev_alias!} @ {!dev_agency!}
 *
 * TOC:
 * 
 * CSS Custom properties
 * Plugins style's override
 * Base
 * Layout
 * * Header
 * * Slider
 * * Sections
 * * Footer
 * * Helpers
 * Media queries
 * 
 * @todo Add each module/section needed
 * 
 */

/* CSS Custom Properties */
@import '../plugins/magicssian/_project_variables.css';


/* Plugins style's override */
/* Swiper */
.swiper-container .swiper-button-prev {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='65' viewBox='0 0 25 65'%3E%3Cpolygon points='23 65 25 65 2 32.5 25 0 23 0 0 32.5 ' fill='%23098EAD'/%3E%3C/svg%3E");
}
.swiper-container .swiper-button-next {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='65' viewBox='0 0 25 65'%3E%3Cpolygon points='2 0 0 0 23 32.5 0 65 2 65 25 32.5 ' fill='%23098EAD'/%3E%3C/svg%3E");
}
.swiper-container .swiper-button-prev, 
.swiper-container .swiper-button-next {
  outline: 0;
}
.swiper-pagination-fraction, 
.swiper-pagination-custom, 
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 60px;
}
.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 100%;
  background: rgba(255, 255, 255, .3);
  border: 2px solid #fff;
  opacity: 1;
}
.swiper-pagination-bullet-active:after {
  display: block;
  content: '';
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 100%;
  margin: 2px auto;
}
/* Grid  */
.grid.gutter-row > .column {
  padding: 0 10px;
}
.grid.gutter-row > .column:first-child {
  padding-right: 20px;
  padding-left: 0;
}
.grid.gutter-row > .column:last-child {
  padding-right: 0;
  padding-left: 20px;
}
@media (max-width: 780px) {
  .grid.gutter-row > .column, 
  .grid.gutter-row > .column:first-child, 
  .grid.gutter-row > .column:last-child {
    padding: 0;
  }
}
/* Base */
p, ul {
  line-height: 1.75rem;
}
h1 {
  font-size: 4rem;
}
h1, h2, h3, h4, h5 {
  line-height: 1.2em;
  margin: 0;
  font-weight: 900;
}
h1, .h1, h2, .h2 {
  line-height: 1em;
}
h5 {
  line-height: var(--rhytm-unit);
}
hr {
  margin-left: 0;
  margin-right: 0;
}
hr.align-c {
  margin-left: auto;
  margin-right: auto;
}
hr.neutral {
  background-color: var(--neutral-color);
}
hr.decoration:after {
  content: '';
  position: absolute;
  left: 0;
  display: block;
  height: 1px;
  width: 150px;
  background-color: var(--complementary-color-1);
}
hr.short {
  width: 150px;
}
a.white:link, a.white:visited {
  color: #fff;
}
a.black:link, a.black:visited, 
.c-black {
  color: #000;
}
a.variant.active {
  color: #000;
  border-color: transparent;
}
.white, 
.heading.white {
  color: #fff;
}
.button, a.button, button, input[type=button], input[type=reset], input[type=submit] {
  border-width: 1px;
  padding: 9px 20px;
}
ul.custom.no-decoration {
  padding-left: 0;
}
ul.custom.no-decoration li:before {
  display: none;
}
fieldset {
  border: 0;
  padding: 0;
}
a.bulleted {
  position: relative;
  text-transform: uppercase;
  color: var(--base-color);
  display: inline-block;
  padding-left: var(--rhytm-unit);
}
a.bulleted:hover, a.bulleted:active, a.bulleted.active {
  color: var(--complementary-color-1);
}
a.bulleted:before {
  position: absolute;
  left: 0;
  top: calc(.25 * var(--rhytm-unit));
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  background: transparent;
  border-top: calc(.18 * var(--rhytm-unit)) solid transparent;
  border-bottom: calc(.18 * var(--rhytm-unit)) solid transparent;
  border-left: calc(.18 * var(--rhytm-unit)) solid var(--complementary-color-1);
}
a.bulleted.align-r {
  padding-left: 0;
  padding-right: var(--rhytm-unit);
}
a.bulleted.align-r:before {
  left: auto;
  right: 0;
}
img.responsive {
  width: 100%;
  height: auto;
}
/* Layout */
.banner {
  height: 520px;
}
.banner.main {
  height: 600px;
  margin-bottom: 100px;
}
.banner p {
  text-align: justify;
  text-align-last: center;
}
.content aside.column {
  padding-right: var(--rhytm-unit);
}
.content aside.column,
.content article.column {
  margin-bottom: calc(3 * var(--rhytm-unit));
}
/* * Header */
header {
  position: absolute;
  z-index: 999;
  width: 100%;
  transition:.4s ease-out;
}
.header-logo {
  width: auto;
  height: 100%;
  position: relative;z-index: 9999;
}
.contact-info {
  background: rgba(0, 0, 0, .6);
  height: 40px;
  margin-bottom: 30px;
}
.contact-info p {
  margin: 0;
  line-height: 40px;
}
.contact-info p.compact {
  font-size: .75rem;
}
.contact-info a.bulleted {
  text-transform: none;
}
/* * Navigation */
header .navigation .menu {
  line-height: 144px;
}
a.main:link, a.main:visited {
  padding: 10px;
  font-family: var(--heading-font);
  font-weight: 700;
  text-transform: uppercase;
}
a.main:hover, a.main:active, 
a.main.active {
  color: #fff;
  border-color: var(--complementary-color-1);
}
header.collapsed {
  position: fixed;
  width: 100%;
  height: 60px;
  background: rgba(0, 0, 0, .8);
}
header.collapsed .contact-info {
  display: none;
}
header.collapsed .menu {
  line-height: 54px;
}
header .header-logo-wrapper {
  transition:.4s all ease-out;
}
header.collapsed .header-logo-wrapper {
  height: 44px;
  margin-top: 8px;
}
header.collapsed .dropdown ul {
  background-color: rgba(0, 0, 0, .8);
}
/* * Main Slider */
.swiper-slide img.responsive {
  width: 100%;
}
.swiper-slide .grid {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 100;
}
/* * Partners Slider */
.swiper-container.partners {
  padding-top: 60px;
  padding-bottom: 60px;
  max-width: 1340px;
}

.shortcut-about {
  background: transparent url('../img/background-shortcut-home-about.jpg') center top no-repeat;
  background-size: auto 100%;
  margin-bottom: 100px;
}
.shortcut-about .content {
  padding-top: 70px;
  padding-right: 50px;
  text-align: justify;
}

.shortcut-blog {
  margin-bottom: 80px;
}
.latest-post {
  padding-right: 40px;
}
.recent-posts .categories a {
  margin-right: 27px;
  border: 0;
}
.post-preview img.thumbnail {
  margin-right: 16px;
}
.post-preview .heading {
  text-transform: none;
}

.audiovisual-latest {
  margin-bottom: 100px;
}
.audiovisual-latest .audiovisual-container {
  border: 1px solid var(--neutral-color);
  padding: 10px;
}
.audiovisual-latest iframe, 
.latest-post iframe {
  width: 100%;
  max-width: 100%;
  height: 170px;
  margin-bottom: 20px;
}
.latest-post iframe {
  height: 425px;
}

/* * Latest post Pop up */
.latest-post.splash {
    padding-right: 0;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    z-index: 999;
}
.latest-post.splash h5, 
.latest-post.splash hr {
  display: none;
}
.latest-post.splash .container {
  width: 994px;
  max-width: 80%;
  height: 500px;
  max-height: 80%;
  position: absolute;
  left: calc(50% - 492px);
  top: calc(50% - 250px);
}
.latest-post.splash .container .post-image {
  width: 690px;
  max-width: 80%;
  height: 500px;
  max-height: 100%;
  object-fit: cover;
}
.latest-post.splash .container .content {
  position: absolute;
  right: 0;
  top: 80px;
  width: 480px;
  height: auto;
  background: #fff;
  padding: 40px 30px;
}
.latest-post.splash .container .content .button {
  margin-top: 20px;
}
.latest-post .link.button.close-button {
  display: none;
  position: absolute;
  top: 0;
  right: -120px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='65' viewBox='0 0 25 65'%3E%3Cpolygon points='2 0 0 0 23 32.5 0 65 2 65 25 32.5 ' fill='%23098EAD'/%3E%3C/svg%3E"), 
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='65' viewBox='0 0 25 65'%3E%3Cpolygon points='23 65 25 65 2 32.5 25 0 23 0 0 32.5 ' fill='%23098EAD'/%3E%3C/svg%3E");
  background-position: 14px center, 28px center;
  background-repeat: no-repeat, no-repeat;
  background-size: 20px 40px;
  width: 65px;
  height: 65px;
  margin: 0;
  border-radius: 33px;
  line-height: 9999;
  overflow: hidden;
}
.latest-post.splash .link.button.close-button {
  display: block;
}
@media (max-width: 1040px) {
  .latest-post.splash .container {
    left: 10%;
    top: 20%;
  }
  .latest-post .link.button.close-button {
    top: 0;
    right: 0;
  }
}


.banner.why {
  background: transparent url('../img/banner-why.jpg') center no-repeat;
  background-size: cover;
}
.banner .container {
  height: 100%
}
/* * Sections */
/* * * Firma */
.about {
  margin-bottom: calc(3 * var(--rhytm-unit));
  text-align: justify;
}
.about .column:first-child {
  padding-right: var(--rhytm-unit);
}
.about .column:last-child {
  padding-left: var(--rhytm-unit);
}
.mision-vision {
  text-align: justify;
  text-align-last: center;
  padding: calc(3 * var(--rhytm-unit));
  background-color: var(--neutral-color);
}
.mision-vision .column {
  padding-left: var(--rhytm-unit);
  padding-right: var(--rhytm-unit);
}
.mision-vision .column:first-child {
  padding-left: 0;
}
.mision-vision .column:last-child {
  padding-right: 0;
}
/* * * Equipo */
a.toogle-link {
  font-weight: normal;
  margin-left: 20px;
}
.team-bio h5 {
  padding-bottom: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  border-bottom: 1px solid var(--neutral-color);
}
.team-bio h5 + ul {
  margin-bottom: 40px;
}
/* * * Contacto */
.contact {
  background: #072c3d url('../img/background-screen-lines.png') left top repeat;
  color: #fff;
  padding-top: 60px;
  padding-bottom: 60px; 
}
.contact .grid .column:first-child {
  padding-right: 40px;
}
.grecaptcha-badge {
  box-shadow: none!important;
  margin-bottom: 40px;
}
/* * * Blog */
.post-preview {
  margin-bottom: var(--rhytm-unit);
}
.post-image {
  margin-bottom: calc(1.5 * var(--rhytm-unit));
}
/* * Newsletter */
.section.newsletter-subscription {
  background: #030C11;
  color: #fff;
  padding-top: 60px;
  padding-bottom: 60px;
}
.section.newsletter-subscription button {
  margin-bottom: 40px;
  margin-left: 20px;
}
@media (max-width: 1040px) {
  .section.newsletter-subscription button {
    margin-left: 0;
  }
}

.section.newsletter-subscription .grecaptcha-badge {
  margin-bottom: 0;
}

/* * Oferta laboral */
.job_offers_list {
  padding-bottom: 60px;
}
.job_offers_list .job_offer_shortcut:last-child {
  margin-bottom: 0;
}
.job_offer_shortcut {
  border: 1px solid #f0f0f0;
  border: 1px solid var(--neutral-color);
  padding: 10px;
  margin-bottom: 30px;
}


/* * Footer */
footer {
  background-color: #041722;
  padding-top: 60px;
  padding-bottom: 60px;
}
footer nav {
  margin-bottom: 60px;
  padding-bottom: 59px;
  border-bottom: 1px solid #1c2d37;
}

.floating-whatsapp {
    position: fixed;
    z-index: 99999;
    margin-right: 70px;
    padding: 10px 20px 10px 20px;
    border-radius: 25px 0 0 25px;
    bottom: 70px;
    right: 30px;
    font-size: 1rem;
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 100%;
    background: #fff;
    color: #000!important;
    transition: 0.1s ease-out;
    filter: drop-shadow(0 0 4px #000);
}
.floating-whatsapp:before {
    content: "";
    position: absolute;
    display: block;
    right: -70px;
    top: -74%;
    width: 80px;
    height: 80px;
    background-image: url(../img/icon_floating_chat.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 100%;
}
.floating-whatsapp:hover {
    translate: -1px
}
.floating-whatsapp:hover span {
    display: block;
}

a.suite100 {   
  width: 19px;
  height: 19px;
  display: block;
  background: transparent url('../img/logo-suite100.svg');
  background-position: left center;
  background-repeat: no-repeat;
}
a.suite100:hover, a.suite100:active {
    width: 70px;
}
/* helpers */
.upper {
  text-transform: uppercase;
}

/*! * Dropdown navigation */
.dropdown, 
.dropdown > li {
  cursor: default;
  display: inline-block;
}
.dropdown ul {
  display: none;
  position: absolute;
  left: 0;
  top: 30px;
  width: max-content;
  padding-left: 5px;
}
.dropdown li:hover > ul {
  display: inline-block;
}
.dropdown ul li a.sub {
  padding: 0 5px;
  margin: 0 10px;
}
header .navigation .dropdown {
  display: inline;
}
.dropdown, .dropdown ul {
  margin: 0;
}
.dropdown ul {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--complementary-color-1);
}
.dropdown li:hover > a.main {
  border-bottom-color: transparent;
  cursor: default;
}
a.sub:link, a.sub:visited {
  color: #fff;
}

/* Media queries */
@media (max-width: 1440px) {
  .swiper-container.home {
    height: 800px;
  }
  .swiper-slide img.responsive {
    height: 100%;
    width: auto;
    left: 0;
    right: 0;
  }
  .swiper-slide .container .heading {
    padding-top: 100px;
  }
  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 30px;
  }
}
@media (max-width: 1280px) {}
@media (max-width: 1140px) {
  .container.boxed {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (max-width: 1024px) {}
@media (max-width: 980px) {}
@media (max-width: 780px) {
  p, ul {
    line-height: 1.5rem;
  }
  .contact-info a.language_switch {
    display: none;
  }
  .navigation label {
    float: left;
    margin-top: 10px;
  }
  .swiper-container.home {
    height: 600px;
  }
  header .header-logo-wrapper {
    float: right;
    height: 54px;
  }
  header.collapsed .navigation label {
    margin-top: 14px;
  }
  a.main {
    display: block;
  }
  .navigation.slide-l .menu {
    width: 100%;
    left: -100vw;
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 140px;
  }
  .navigation .menu {
    height: calc(100vh - 40px);
    top: 40px;
    width: 50vw;
    padding-top: 100px;
    background: rgba(0, 0, 0, .9);
    text-align: left;
  }
  header .navigation .dropdown {
    display: block;
  }
  a.main {
    width: auto;
  }
  header.collapsed {
    background-color: rgba(0, 0, 0, .9);
  }
  header.collapsed .navigation .menu {
    height: calc(100vh - 60px);
    top: 60px;
    padding-top: 20px;
    background: rgba(0, 0, 0, .9);
  }
  header.collapsed .dropdown ul, 
  header .dropdown ul {
    background-color: rgba(0, 0, 0, .0);
  }
  .dropdown ul.custom.no-decoration {
    display: block;
    position: relative;
    top: 0;
    border-bottom: 0;
    padding-left: 10px;
  }
  .dropdown a.main:link, 
  .dropdown a.main:hover {
    border-bottom-color: transparent;
  } 
  .swiper-slide .container .heading {
    padding-top: 70px;
  }
  .shortcut-about {
    background-image: none;
  }
  .shortcut-about .content {
    padding-top: 70px;
    padding-right: 0;
  }
  .about .column:first-child, 
  .about .column:last-child {
    padding-right: 0;
    padding-left: 0;
  }
  .mision-vision {
    padding: var(--rhytm-unit) 0;
  }
  .content.team {
    flex-direction: column-reverse;
  }
  .contact .grid .column:first-child {
    padding-right: 0;
    padding-bottom: calc(2 * var(--rhytm-unit));
  }
  footer p {
    text-align: left;
  }
}
@media (max-width: 640px) {
  .banner.main {
    height: 300px;
    margin-bottom: 60px;
  }
  .container.boxed {
    padding-left: 20px;
    padding-right: 20px;
  }
  .contact-info p.extended {
    display: none;
  }
  .contact-info p.compact {
    display: block;
  }
  .swiper-slide .container .heading {
    padding-top: 60px;
    font-size: 3rem;
  }
}
@media (max-width: 480px) {
  .swiper-slide .container .heading {
    padding-top: 60px;
    font-size: 2.25rem;
  }
  .swiper-slide .container p {
    line-height: 1.5em;
    padding: 0 20px;
  }
  .contact .heading, 
  .banner.why .heading {
    font-size: 2.5rem;
  }
  .banner.why .column {
    overflow: visible;
  }
}


